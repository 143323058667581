@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500&display=swap");

#root,
#root > div,
.tc-full-height {
  height: 100%;
}

a,
.tc-link {
  color: #1599bc;
  text-decoration: none;
}

small {
  font-size: 0.6875rem;
  color: #898f91;
}

.tc-bold {
  font-weight: 500 !important;
}

.tc-color-red {
  color: #e74c3c !important;
}

.tc-cloud-off,
.tc-color-gray {
  color: #898f91;
}

.tc-bg-white {
  background: #fff;
}

.tc-blue {
  color: #004763 !important;
}

.tc-label {
  color: #898f91;
  font-size: 0.875rem;
}

.tc-label.tc-shrink {
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
}

.tc-lh-1 {
  line-height: 1;
}

.tc-cloud-off,
.tc-text-center {
  text-align: center;
}

/* footer */

.tc-footer {
  overflow: hidden;
  background-color: #ffffff;
  padding: 8px 16px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
  min-height: 52px;
  z-index: 10;
}

.tc-footer.tc-no-padding {
  padding: 0 !important;
  min-height: initial;
}

/* Paddings & Margins */

.tc-p0 {
  padding: 0 !important;
}

.tc-pl1 {
  padding-left: 0.5rem !important;
}

.tc-pt2 {
  padding-top: 16px !important;
}

.tc-pt10px {
  padding-top: 10px !important;
}

.tc-pt12px {
  padding-top: 12px !important;
}

.tc-pt15px {
  padding-top: 15px !important;
}

.tc-m-2px {
  margin: -2px;
}

.tc-m2px {
  margin: 2px;
}

.tc-ml1 {
  margin-left: 8px !important;
}

.tc-mr1 {
  margin-right: 8px !important;
}

.tc-mt0 {
  margin-top: 0 !important;
}

.tc-mt05 {
  margin-top: 0.5rem !important;
}

.tc-mt1 {
  margin-top: 8px !important;
}

.tc-mt2 {
  margin-top: 16px !important;
}

.tc-mt60px {
  margin-top: 60px !important;
}

.tc-mb0 {
  margin-bottom: 0 !important;
}

.tc-mb05 {
  margin-bottom: 0.5rem !important;
}

.tc-mb1 {
  margin-bottom: 8px !important;
}

.tc-mb2 {
  margin-bottom: 1rem !important;
}

.tc-p1 {
  padding: 8px !important;
}

.tc-p2 {
  padding: 16px !important;
}
.tc-pr0 {
  padding-right: 0px !important;
}
.tc-pr05 {
  padding-right: 0.5rem !important;
}
.tc-pl2 {
  padding-left: 16px !important;
}
.tc-pr2 {
  padding-right: 16px !important;
}
.tc-pb0 {
  padding-bottom: 0 !important;
}
.tc-pb1 {
  padding-bottom: 8px !important;
}
.tc-pb2 {
  padding-bottom: 16px !important;
}

.tc-mtb-4px {
  margin: -4px 0 !important;
}

/* Display */
.tc-badge {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.tc-none {
  display: none !important;
}

.tc-nowrap,
.tc-ellipsis {
  white-space: nowrap !important;
}

.tc-ellipsis,
.tc-o-hidden,
.tc-o-y-auto {
  overflow: hidden !important;
}

.tc-o-y-auto {
  overflow-y: auto !important;
}

.tc-ellipsis {
  text-overflow: ellipsis !important;
}

.tc-full-width {
  width: 100%;
}

/* Position */
.tc-relative {
  position: relative !important;
}

.tc-absolute {
  position: absolute !important;
}

.tc-z-index-1 {
  z-index: 1;
}

/* Flex */
.tc-flex {
  display: flex;
}

.tc-flex-grow {
  flex-grow: 1;
}

.tc-flex-wrap {
  flex-wrap: wrap;
}

.tc-flex-nowrap {
  flex-wrap: nowrap;
}

.tc-flex-end {
  justify-content: flex-end;
}

.tc-grid-height-xs3 {
  height: calc(100% + 24px);
}

/* pages */
.tc-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden !important;
}

.tc-page-content {
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;
  padding: 1rem;
  position: relative;
}

.tc-page-content.tc-no-padding {
  padding: 0 !important;
}

.tc-page-content.tc-no-scroll {
  overflow-y: hidden !important;
}

/* boxes */
.tc-box-content {
  padding: 1rem 1.5rem;
}

/* navs */
.tc-nav {
  background-color: #f4f8f9;
}

.tc-nav a,
.tc-nav button {
  min-width: initial !important;
}

@media (min-width: 600px) {
  .tc-nav {
    min-width: initial !important;
  }
}

.tc-nav-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #004763;
  padding-left: 40px !important;
  padding-right: 32px !important;
  font-size: 1.5rem;
}

/* cards */
.tc-card-header {
  background-color: #f4f8f9;
}
.tc-card-title {
  font-size: 1.25rem !important;
  color: #004763;
}

/* Shadows */
.tc-footer.tc-shadow {
  box-shadow: 0px -1px 4px -1px rgb(0 0 0 / 20%), 0px -4px 5px 0px rgb(0 0 0 / 14%), 0px -1px 10px 0px rgb(0 0 0 / 12%) !important;
}
.tc-shadow-l {
  box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
}

/* buttons */
.tc-button-danger {
  background-color: #e74c3c !important;
  color: #fff !important;
}
.tc-button-danger:hover {
  background-color: rgb(226, 39, 14) !important;
}
.tc-button-danger:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

/* box arrows */
.tc-arrow,
.tc-barrow {
  transform: rotate(180deg) !important;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.tc-arrow {
  position: relative !important;
  top: 3px !important;
}

.tc-arrow.tc-expand,
.tc-barrow.tc-expand {
  transform: rotate(0deg) !important;
}

/* Search Select */
label + .tc-srch-sel-container {
  margin-top: 1rem;
}

.tc-srch-sel-control {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 0.875rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
}

.tc-srch-sel-control:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}

.tc-srch-sel-control:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #107a98;
  pointer-events: none;
}

.tc-srch-sel-control:hover:not(.tc-srch-sel-disabled):before {
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}

.tc-srch-sel-control.tc-srch-sel-disabled:before {
  border-bottom-style: dotted;
}

.tc-srch-sel-control.tc-srch-sel-focused:after,
.tc-srch-sel-container.tc-srch-sel-error .tc-srch-sel-control:after {
  transform: scaleX(1);
}

.tc-srch-sel-arrow,
.tc-arrow.tc-srch-sel-arrow {
  color: rgba(0, 0, 0, 0.54);
  top: 0px !important;
  cursor: pointer;
}

.tc-srch-sel-arrow.tc-srch-sel-arrow-disabled {
  color: rgba(0, 0, 0, 0.26);
}

.tc-srch-sel-container.tc-srch-sel-error .tc-srch-sel-control:after {
  border-bottom-color: #f44336;
}

/* Main Menu */
.tc-main-menu-item {
  flex-direction: column;
  padding-top: 15px !important;
  padding-bottom: 10px !important;
  border-left: solid 4px transparent !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.tc-main-menu-item:hover,
.tc-main-menu-item:focus,
.tc-main-menu-item.Mui-selected {
  border-left-color: #004763 !important;
}
.tc-main-menu-item svg {
  color: #648c9b;
}

/* Cloud Off */

/* upload */
.tc-upd-dropzone {
  margin-bottom: 16px;
}

.tc-upd-dropzone > div {
  background-color: #f3f3f3;
  padding: 22px;
  text-align: center;
  color: #898f91;
}

.tc-cloud-off svg,
.tc-upd-dropzone > div svg {
  font-size: 4.125rem;
  margin-bottom: -12px;
}
.tc-upd-dropzone > div button {
  margin-top: 24px;
}

.tc-upd-dropzone > div.tc-upd-hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: rgba(0, 0, 0, 0.3) !important;
}
.tc-upd-dropzone > div.tc-upd-error {
  background-color: rgba(221, 75, 57, 0.3) !important;
}

/* file viewer */
.tc-viewer-tools {
  padding: 4px 8px 2px 8px;
  background-color: #ffffff;
}

.tc-page-content.react-transform-component {
  width: 100% !important;
  overflow: hidden;
  background-color: #e7eef1;
  padding: 0 !important;
}

.tc-page-content.react-transform-component > .react-transform-element {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tc-modal-viewer {
  padding: 0 !important;
  overflow: initial;
  flex-grow: 1;
  height: calc(100vh - 172px);
}

.tc-modal-viewer > div {
  height: 100%;
}

.tc-viewer-image {
  user-select: none;
  -webkit-user-select: none;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  background-image: linear-gradient(45deg, #d3d4d6 25%, transparent 25%), linear-gradient(-45deg, #d3d4d6 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #d3d4d6 75%), linear-gradient(-45deg, transparent 75%, #d3d4d6 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.tc-upload-wc {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media print {
  /* Hide everything in the root when printing... */
  #root {
    display: none;
  }
  .print-hide {
    display: none !important;
  }
  /* ...except our special div. */
  .print-area {
    display: block;
  }
}

@media screen {
  .print-area {
    display: none;
  }
}

.tc-no-padding-tb {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

div[class^="MTableToolbar-actions-"] {
  white-space: nowrap !important;
}
